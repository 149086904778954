import './Header.scss';

import { motion } from 'framer-motion';
import React, {  useState } from 'react';
import Highlight from 'react-highlight';

import { images } from '../../constants';
import program from '../../constants/program';
import { AppWrap } from '../../wrapper';


function Header () { 
  const [lang,setLang] = useState("python")

  const [code,setCode] = useState(program["python"])
  const changeHeader = (lang)=>{
    setLang(lang)
    setCode(program[lang])
    
  
  }
  
return(

  <div className="app__header">
    <motion.div
    className='app__header-info'
    whileInView={{ x: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 0.9,ease:"easeIn" }}
    >
     
        <h4> Hi there 👋, I am</h4>
        <h2>Ayush Niroula</h2>
        <p>Creative Full Stack Developer</p>
        <a href='#about' className='app__header-btn app__flex'>Learn More</a>
</motion.div>
<div className='app__header-right'>


<motion.div
 whileInView={{ x: [100, 0], opacity: [0, 1] }}
 transition={{ duration: 0.9,ease:"easeIn" }}
className='app__header-box'>
  <div className="box__wrapper">
 
  </div>
 <div
 className='box'
 >
   <Highlight 
   style={{"postion":'relative'}}
   className={lang}>
  {code}
</Highlight>
     {/* <div className="code__container">
       
       
     </div> */}

 </div>

</motion.div>
<motion.div 
 whileInView={{ x: [-100, 0], opacity: [0, 1] }}
 transition={{ duration: 0.9,ease:"easeIn" }}
className='icon'>
  <div  onClick={()=>changeHeader("javascript")}className='img_cnt'>
  <img src={images.javascript} alt="javascript"/>
  </div>
  <div  onClick={()=>changeHeader("python")} className='img_cnt'>
  <img src={images.python} alt="python" />
  </div>
  <div  onClick={()=>changeHeader("go")} className='img_cnt'>
  <img src={images.golang}  alt="go"/>
  </div>

   
 </motion.div>

</div>

  </div>
  
)    
}


export default AppWrap(Header, 'home');
