import React from 'react';
import { BsGithub, BsLinkedin } from 'react-icons/bs';



const SocialMedia = () => 
{
  const pushTo = (url)=>{
    window.open(url,'_blank')
  
  }
return(
  <div className="app__social">
    <div 
    onClick={()=>pushTo("https://www.linkedin.com/in/ayush-niroula-431a891a5/")}
    >
      <BsLinkedin />
    </div>
    <div
    onClick={()=>pushTo("https://github.com/DarkWorldCoder")}
    >
      <BsGithub />
    </div>
    
  </div>
)};

export default SocialMedia;
