
const program =   {
    "python":`
    class Programmer:
      
      def __init__(self):
        
        self.name = "Ayush Niroula"
        self.designation = "Full stack Developer"
        self.interest = "AI, Data science"
      
      def __str__(self):
        
        return "My Name is" + self.name + "Have a
        experience of" + self.designation + "and focused
        to provide the best" 
      
      def strength(self):
      
        print "I am self taught and  good at google
        search😉 "
    `,
    "javascript":`
    class Programmer{

        constructor(){
            this.name = "Ayush Niroula"
            this.designation = "Full stack"
            this.interest = "AI, Data science"
        }

        function aboutMe(){
            return "My Name is" + this.name + "Have a
            experience of" + this.designation + 
            "and focused to provide the best"
        } 

        function strength(){
            return "I am self taught and  good at google
        search😉 "
        }

    }
    `,
    "go":`
    package main
    import "fmt"

    type Programmer struct{
        Name string
        Designation string
        Interest string
    }
    
    func createPerson(name,designation,interest string)
    *Programmer{
        p := Programmer{
            Name:name
            Designation:designation
            Interest:Interest
        }
        return &p
    }
    func main(){
         createPerson("Ayush Niroula",
         "Full Stack Developer","AI, Data Science")
    }
    `
}
export default program